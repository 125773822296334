<template>
  <div class="page-content">
      <sidebar-menu />
      <router-view />
  </div>
</template>

<script>
import SidebarMenu from '../components/common/SidebarMenu.vue'
export default {
  components: {
    SidebarMenu
  }
}
</script>
